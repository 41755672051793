import { Flex, Text } from "@chakra-ui/react";
import Loading from "../Loading";

const TitleWithBoldComplement = ({
  title,
  boldComplement,
  loading,
  color = "white",
  isStatus = false,
  fontSize = "16px",
}) => {
  if (loading) {
    return <Loading loading={loading} />;
  }

  return (
    <Flex gap="10px">
      <Text fontSize={fontSize} color={isStatus ? "white" : color}>
        {title}
      </Text>
      <Text fontWeight="700" fontSize={fontSize} color={color}>
        {boldComplement}
      </Text>
    </Flex>
  );
};

export default TitleWithBoldComplement;
