import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "../pages/home";
import Session from "../pages/session";
import Login from "../pages/login";
import Footer from "../components/Footer";
import Users from "../pages/users";
import Header from "../components/Header";
import { useEffect, useState } from "react";

export default function RoutesApp() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/sessions" element={<Home />} />
        <Route path="/usuarios" element={<Users />} />
        <Route path="/session/:id" element={<Session />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}
