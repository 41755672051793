import { ChakraProvider } from "@chakra-ui/react";
import RoutesApp from "./routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider>
        <RoutesApp />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          closeOnClick
          rtl={false}
          theme="dark"
        />
      </ChakraProvider>
    </QueryClientProvider>
  );
}

export default App;
