import fastApi from "./fastApi";

export const fetchUsers = async () => {
  try {
    const data = await fastApi.get("/user").then((response) => response.data);

    return data;
  } catch (err) {
    console.log("ERROR", err);
  }
};

export const createUser = async (props) => {
  try {
    const data = await fastApi
      .post("/user", props)
      .then((response) => response.data);

    return data;
  } catch (err) {
    console.log("ERROR", err);
  }
};

export const editUser = async (props) => {
  try {
    const data = await fastApi
      .put(`/user/${props.id}`, props)
      .then((response) => response.data);

    return data;
  } catch (err) {
    console.log("ERROR", err);
  }
};

export const deleteUser = async (id) => {
  try {
    const data = await fastApi
      .delete(`/user/${id}`)
      .then((response) => response.data);

    return data;
  } catch (err) {
    console.log("ERROR", err);
  }
};

export const login = async (props) => {
  try {
    const data = await fastApi
      .post("/login/validate", props)
      .then((response) => response.data);

    return data;
  } catch (err) {
    console.log("ERROR", err);
  }
};
