import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { getColor } from "../../styles/colors";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createUser,
  deleteUser,
  editUser,
  fetchUsers,
} from "../../services/users";
import TitleWithBoldComplement from "../../components/TitleWithBoldComplement";
import { IoMdAdd } from "react-icons/io";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getCache } from "../../services/cache";
import { useNavigate } from "react-router-dom";
import { BsTrash } from "react-icons/bs";
import { FaEdit, FaHome } from "react-icons/fa";

const userTypeOptions = [
  {
    id: "ADMIN",
    label: "ADMIN",
  },
  {
    id: "NIVEL01",
    label: "NIVEL01",
  },
  {
    id: "NIVEL02",
    label: "NIVEL02",
  },
];

const ModalCreateUser = ({ isOpen, onClose, userSelected = null }) => {
  const [userType, setUserType] = useState("ADMIN");
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (props) =>
      userSelected?.id ? editUser(props) : createUser(props),
    onSuccess: (response) => {
      if (response) {
        setUser("");
        setUserType(userTypeOptions[0].id);
        setPassword("");
        toast.success(
          userSelected?.id
            ? "Usuário Editado com sucesso!"
            : "Usuário criado com sucesso!"
        );
        queryClient.invalidateQueries("users");
        onClose();
      } else {
        toast.error(
          userSelected?.id
            ? "Erro ao editar usuário!"
            : "Erro ao criar usuário!"
        );
      }
    },
    onError: () => {
      toast(
        userSelected?.id
          ? "Erro ao editar usuário, tente novamente mais tarde"
          : "Erro ao criar usuário, tente novamente mais tarde"
      );
      setUser("");
      setUserType(userTypeOptions[0].id);
      setPassword("");
      onClose();
    },
  });

  useEffect(() => {
    setUser(userSelected?.user);
    setUserType(userSelected?.userType);
    setPassword(userSelected?.password);
  }, [userSelected]);

  const allFieldsIsValid = user && password;

  const title = userSelected?.id ? "Editar Usuário" : "Criar Usuário";

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form>
            <Flex
              flexDirection="column"
              gap="10px"
              width="100%"
              paddingBottom="10px"
            >
              <FormControl>
                <FormLabel>Usuário</FormLabel>
                <Input
                  type="text"
                  value={user}
                  onChange={({ target }) => setUser(target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Senha</FormLabel>
                <Input
                  type="password"
                  value={password}
                  onChange={({ target }) => setPassword(target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Tipo</FormLabel>
                <Select
                  onChange={({ target }) => setUserType(target.value)}
                  value={userType}
                  defaultValue={userTypeOptions[0].id}
                >
                  {userTypeOptions.map(({ id, label }) => (
                    <option key={id} value={id}>
                      {label}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <Button
                onClick={() =>
                  allFieldsIsValid
                    ? mutation.mutate(
                        userSelected
                          ? {
                              id: userSelected?.id,
                              user,
                              password,
                              userType: userType ?? "ADMIN",
                              sessions: userSelected?.sessions,
                            }
                          : { user, password, userType: userType ?? "ADMIN" }
                      )
                    : toast.error("Preencha todos os campos!")
                }
                isLoading={mutation.isPending}
              >
                {userSelected?.id ? "Editar Usuário" : "Criar Usuário"}
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default function Users() {
  const { data: users } = useQuery({
    queryKey: ["users"],
    queryFn: () => fetchUsers(),
  });

  const navigate = useNavigate();

  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [userSelected, setUserSelected] = useState({});
  const [loggedUser, setLoggedUser] = useState({});

  useEffect(() => {
    const verifyUser = () => {
      const user = getCache("user");
      const parsedUser = JSON.parse(user);
      const userIsValid = parsedUser?.id;
      const userIsAdmin = parsedUser?.userType === "ADMIN";

      if (!userIsValid) {
        navigate("/");
      }

      if (!userIsAdmin) {
        navigate("/sessions");
      }

      setLoggedUser(parsedUser);
      return;
    };

    verifyUser();
  }, [navigate]);

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (id) => deleteUser(id),
    onSuccess: () => {
      toast.success("Usuário deletado com sucesso!");
      queryClient.invalidateQueries("users");
    },
    onError: () => {
      toast("Erro ao deletar usuário, tente novamente mais tarde");
    },
  });

  return (
    <Flex
      width="100%"
      minH="calc(100vh - 150px)"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap="20px"
      padding="15px"
      backgroundColor={getColor("CAPE")}
    >
      <Flex gap="20px">
        {" "}
        <Button
          leftIcon={<IoMdAdd />}
          borderRadius="0px"
          backgroundColor={getColor("TOXIC_ZOE_LAVERNE")}
          color="white"
          _hover={{ backgroundColor: getColor("TOXIC_ZOE_LAVERNE") }}
          onClick={onOpen}
        >
          Criar Usuário
        </Button>
      </Flex>
      <Grid
        templateColumns={isLargerThan800 ? "repeat(4, 1fr)" : "repeat(1, 1fr)"}
        gap={10}
        justifyItems="center"
      >
        {users?.map(({ id, user, userType, password, sessions }) => (
          <Flex
            key={id}
            flexDirection="column"
            backgroundColor={getColor("DRAGO_DE_SAO_FRANCISCO")}
            alignItems="center"
            minW="250px"
          >
            <Flex
              width="100%"
              justifyContent="center"
              alignItems="center"
              backgroundColor={getColor("TOXIC_ZOE_LAVERNE")}
              gap="10px"
              padding="10px"
            >
              <Text fontWeight="700" color="white" fontSize="20px">
                {user}
              </Text>
            </Flex>
            <Flex
              width="100%"
              padding="10px"
              gap="10px"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <TitleWithBoldComplement title="" boldComplement={userType} />
              <Flex gap="10px">
                <Box
                  backgroundColor="red"
                  padding="10px"
                  borderRadius="50%"
                  cursor="pointer"
                  onClick={() => mutation.mutate(id)}
                >
                  <BsTrash color="white" />
                </Box>
                <Box
                  backgroundColor={getColor("TOXIC_ZOE_LAVERNE")}
                  padding="10px"
                  borderRadius="50%"
                  cursor="pointer"
                  onClick={() => {
                    setUserSelected({
                      id,
                      user,
                      userType,
                      password,
                      sessions,
                    });
                    onOpen();
                  }}
                >
                  <FaEdit color="white" />
                </Box>
              </Flex>
            </Flex>
          </Flex>
        ))}
      </Grid>

      <ModalCreateUser
        isOpen={isOpen}
        onClose={onClose}
        userSelected={userSelected}
      />
    </Flex>
  );
}
