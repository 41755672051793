import { Flex } from "@chakra-ui/react";
import { getColor } from "../../styles/colors";

export default function Footer() {
  return (
    <Flex
      width="100%"
      height="150px"
      backgroundColor={getColor("CAPE")}
      justifyContent="center"
      alignItems="center"
    >
      <img
        src="https://i.postimg.cc/mDQ8NGJ5/logo-christech-site.png"
        alt="chris tech"
        width="100px"
        height="100px"
      />
    </Flex>
  );
}
