import moment from "moment-timezone";

export const formatDate = (date) => {
  return moment(date);
};

export const formatWithTz = (
  date,
  timezone = "America/Sao_Paulo",
  format = "HH:mm:ss"
) => {
  return moment.tz(date, timezone).format(format);
};
