import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select as SingleSelect,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { formatReal } from "../../libs/StringLib";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  fetchActive,
  fetchBalance,
  fetchSessions,
  fetchOpenBets,
  createSession,
  createSessionWithUser,
  fetchSessionWithUser,
  fetchSession,
} from "../../services/session";
import {
  ArrayIsEmpty,
  arrayLength,
  getFirstElement,
} from "../../libs/ArrayLib";
import { formatDate, formatWithTz } from "../../libs/DateLib";
import { useMediaQuery } from "@chakra-ui/react";
import { getColor } from "../../styles/colors";
import { useNavigate } from "react-router-dom";
import TitleWithBoldComplement from "../../components/TitleWithBoldComplement";
import { IoMdAdd } from "react-icons/io";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCache } from "../../services/cache";
import { fetchUsers } from "../../services/users";
import Select from "react-select";

const ModalCreateSession = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    proxy: "",
  });

  const [selectedOptions, setSelectedOptions] = useState([]);

  const queryClient = useQueryClient();

  const { data: users } = useQuery({
    queryKey: ["users"],
    queryFn: () => fetchUsers(),
  });

  const mutation = useMutation({
    mutationFn: (props) => createSession(props),
    onSuccess: async (response) => {
      const userIds = selectedOptions.map(({ value }) => value);

      if (response === "ERROR") {
        toast.error("Erro ao criar sessão, tente novamente mais tarde!");
      } else {
        toast.success("Sessão criada com sucesso!");

        try {
          const secondResponse = await createSessionWithUser({
            sessionId: response.session_id,
            userIds,
          });

          console.log("Segunda requisição bem-sucedida:", secondResponse);
        } catch (error) {
          console.log("Erro na segunda requisição:", error);
          toast.error(
            "Erro ao criar sessão com usuário, tente novamente mais tarde!"
          );
        }
      }

      setFormData({
        username: "",
        password: "",
        proxy: "",
      });

      setSelectedOptions([]);

      queryClient.invalidateQueries("sessions");
      queryClient.invalidateQueries("sessions-with-users");
      onClose();
    },
  });

  const userOptions = users?.map(({ id, user }) => ({
    label: user,
    value: id,
  }));

  const handleChange = (selected) => {
    setSelectedOptions(selected);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Criar Sessão</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form>
            <Flex
              flexDirection="column"
              gap="10px"
              width="100%"
              paddingBottom="10px"
            >
              <FormControl>
                <FormLabel>Usuário</FormLabel>
                <Input
                  type="text"
                  value={formData.username}
                  onChange={({ target }) =>
                    setFormData({ ...formData, username: target.value })
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Senha</FormLabel>
                <Input
                  type="password"
                  value={formData.password}
                  onChange={({ target }) =>
                    setFormData({ ...formData, password: target.value })
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Proxy</FormLabel>
                <Input
                  type="text"
                  value={formData.proxy}
                  onChange={({ target }) =>
                    setFormData({ ...formData, proxy: target.value })
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Proprietários</FormLabel>
                <Select
                  value={selectedOptions}
                  isMulti
                  name="users"
                  options={userOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={handleChange}
                />
              </FormControl>

              <Button
                onClick={() =>
                  mutation.mutate({
                    ...formData,
                    domain: "https://www.bet365.com/",
                  })
                }
                isLoading={mutation.isPending}
              >
                Criar Sessão
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const sumStakes = (openBets) => {
  return openBets?.reduce((acumulator, { stake }) => {
    return acumulator + stake;
  }, 0);
};

const getNameBySessionStatus = (sessionStatus) => {
  if (sessionStatus) {
    return "Online";
  }

  return "Offline";
};

const SessionCard = ({ session }) => {
  const { id, account } = session;

  const { data: sessionData } = useQuery({
    queryKey: ["session", id],
    queryFn: () => fetchSession(id),
    refetchInterval: 180000,
  });

  const navigate = useNavigate();

  const { data: balance, isLoading: loadingBalance } = useQuery({
    queryKey: ["balance", id],
    queryFn: () => fetchBalance(id),
    refetchInterval: 180000,
  });

  const { data: sessionIsActive, isLoading: loadingSessionIsActive } = useQuery(
    {
      queryKey: ["active", id],
      queryFn: () => fetchActive(id),
      refetchInterval: 180000,
    }
  );

  const { data: sessionsWithUsers, isLoading: loadingSessionsWithUsers } =
    useQuery({
      queryKey: ["sessions-with-users", id],
      queryFn: () => fetchSessionWithUser(),
      refetchInterval: 180000,
    });

  const currentSession = sessionsWithUsers?.find(
    ({ sessionId }) => sessionId === id
  );

  const { data: openBets, isLoading: loadingOpenBets } = useQuery({
    queryKey: ["openBets", id],
    queryFn: () => fetchOpenBets(id),
    refetchInterval: 180000,
  });

  const statusName = getNameBySessionStatus(sessionIsActive);

  const totalStakes = sumStakes(openBets);

  const totalBalance = totalStakes + balance;

  const numberOpenBets = arrayLength(openBets);

  openBets?.sort((a, b) => formatDate(b.date) - formatDate(a.date));

  const lastBet = getFirstElement(openBets);

  return (
    <Flex
      backgroundColor={getColor("DRAGO_DE_SAO_FRANCISCO")}
      flexDirection="column"
      alignItems="center"
      minW="350px"
      key={id}
      gap="20px"
      cursor="pointer"
      onClick={() => navigate(`/session/${id}`)}
    >
      <Flex
        width="100%"
        justifyContent="center"
        alignItems="center"
        backgroundColor={getColor("TOXIC_ZOE_LAVERNE")}
      >
        <Text fontSize="18px" fontWeight="700" py="10px" color="white">
          {account ?? sessionData?.account}
        </Text>
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="center"
        gap="5px"
        paddingBottom="20px"
      >
        <TitleWithBoldComplement
          title="Saldo Total: "
          boldComplement={formatReal(totalBalance)}
          loading={loadingBalance}
          color={getColor("DONT_EMBARRASS_ME")}
        />
        <TitleWithBoldComplement
          title="Saldo Disponível: "
          boldComplement={formatReal(balance)}
          loading={loadingBalance}
        />
        <TitleWithBoldComplement
          title="Saldo Pendente: "
          boldComplement={formatReal(totalStakes)}
          loading={loadingOpenBets}
        />
        <TitleWithBoldComplement
          title="Apostas Pendentes: "
          boldComplement={numberOpenBets}
          loading={loadingOpenBets}
        />
        <TitleWithBoldComplement
          title="Última Aposta: "
          boldComplement={sessionIsActive ? formatWithTz(lastBet?.date) : ""}
          loading={loadingOpenBets}
        />
        <TitleWithBoldComplement
          title="Proprietários: "
          loading={loadingSessionsWithUsers}
        />
        <Flex width="100%" flexWrap="wrap" gap="10px" justifyContent="center">
          {currentSession?.users.map(({ id, user }) => (
            <Text key={id} color="white" fontWeight="700">
              {user}
            </Text>
          ))}
          {ArrayIsEmpty(currentSession?.users) && "-"}
        </Flex>

        <TitleWithBoldComplement
          title="Status: "
          boldComplement={statusName}
          loading={loadingSessionIsActive}
          isStatus={true}
          color={sessionIsActive ? getColor("DONT_EMBARRASS_ME") : "red"}
        />
      </Flex>
    </Flex>
  );
};

export default function Home() {
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userFilter, setUserFilter] = useState("TODOS");
  const [loggedUser, setLoggedUser] = useState({});
  const [filteredSessions, setFilteredSessions] = useState([]);

  const { data: sessions } = useQuery({
    queryKey: ["sessions"],
    queryFn: fetchSessions,
    refetchInterval: 180000,
  });

  const { data: users } = useQuery({
    queryKey: ["users"],
    queryFn: () => fetchUsers(),
  });

  const navigate = useNavigate();

  useEffect(() => {
    const verifyUser = () => {
      const user = getCache("user");
      const parsedUser = JSON.parse(user);
      const userIsValid = parsedUser?.id;

      if (!userIsValid) {
        navigate("/");
      }

      setLoggedUser(parsedUser);
      return;
    };

    verifyUser();
  }, [navigate]);

  const sessionsLoggedUser = sessions?.filter((session) =>
    loggedUser?.sessions?.some(
      (userSession) => session.id === userSession.sessionId
    )
  );

  const { data: sessionsWithUsers, isLoading: loadingSessionsWithUsers } =
    useQuery({
      queryKey: ["sessions-with-users"],
      queryFn: () => fetchSessionWithUser(),
      refetchInterval: 180000,
    });

  useEffect(() => {
    const filteredSessionsWithUsers = sessionsWithUsers?.filter(({ users }) =>
      users.some(({ id }) => id === Number(userFilter))
    );

    setFilteredSessions(filteredSessionsWithUsers);
  }, [userFilter]);

  const userIsAdmin = loggedUser?.userType === "ADMIN";

  console.log("userFilter", userFilter);

  return (
    <Flex
      width="100%"
      minH="calc(100vh - 150px)"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap="10px"
      padding="15px"
      backgroundColor={getColor("CAPE")}
    >
      <Flex gap="20px">
        {userIsAdmin && (
          <Button
            leftIcon={<IoMdAdd />}
            borderRadius="0px"
            backgroundColor={getColor("TOXIC_ZOE_LAVERNE")}
            color="white"
            _hover={{ backgroundColor: getColor("TOXIC_ZOE_LAVERNE") }}
            onClick={onOpen}
          >
            Criar Sessão
          </Button>
        )}

        {userIsAdmin && (
          <Button
            borderRadius="0px"
            backgroundColor={getColor("TOXIC_ZOE_LAVERNE")}
            color="white"
            _hover={{ backgroundColor: getColor("TOXIC_ZOE_LAVERNE") }}
            onClick={() => navigate("/usuarios")}
          >
            Usuários
          </Button>
        )}
      </Flex>
      {userIsAdmin && (
        <Flex flexDirection="column" padding="10px">
          <Text color="white" fontWeight="600">
            Filtro por usuário:
          </Text>
          <SingleSelect
            value={userFilter}
            onChange={({ target }) => setUserFilter(target.value)}
            variant="filled"
          >
            <option value="TODOS">Todos</option>
            {users
              ?.filter(({ sessions }) => !ArrayIsEmpty(sessions))
              .map(({ user, id }) => (
                <option key={id} value={id}>
                  {user}
                </option>
              ))}
          </SingleSelect>
        </Flex>
      )}

      <Grid
        templateColumns={isLargerThan800 ? "repeat(3, 1fr)" : "repeat(1, 1fr)"}
        gap={10}
      >
        {loggedUser?.userType !== "ADMIN" &&
          sessionsLoggedUser?.map((session) => (
            <SessionCard session={session} key={session.id} />
          ))}
        {loggedUser?.userType === "ADMIN" &&
          userFilter === "TODOS" &&
          sessions?.map((session) => (
            <SessionCard session={session} key={session.id} />
          ))}
        {loggedUser?.userType === "ADMIN" &&
          !ArrayIsEmpty(filteredSessions) &&
          filteredSessions?.map(({ sessionId }) => (
            <SessionCard session={{ id: sessionId }} key={sessionId} />
          ))}
      </Grid>
      <ModalCreateSession isOpen={isOpen} onClose={onClose} />
    </Flex>
  );
}
