export const arrayLength = (arr) => {
  if (!Array.isArray(arr)) {
    return null;
  }

  return arr.length;
};

export const ArrayIsEmpty = (arr) => {
  if (!Array.isArray(arr)) {
    return null;
  }

  return arr.length === 0;
};

export const getFirstElement = (arr) => {
  if (!Array.isArray(arr)) {
    return null;
  }

  return arr[0];
};
