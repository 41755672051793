import { Button, Flex, FormControl, FormLabel, Input } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { setCache } from "../../services/cache";
import { toast } from "react-toastify";
import { getColor } from "../../styles/colors";
import { useMutation } from "@tanstack/react-query";
import { login } from "../../services/users";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: (props) => login(props),
    onSuccess: (response) => {
      if (response) {
        setCache("user", JSON.stringify(response));
        navigate("/sessions");
      } else {
        toast.error("Erro ao realizar o login, tente novamente mais tarde!");
      }
    },
    onError: (err) => {
      console.log("ERROR", err);
    },
  });

  const fieldsInvalid = username === "" || password === "";

  return (
    <Flex
      width="100%"
      h="calc(100vh - 150px)"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      backgroundColor={getColor("CAPE")}
      gap="20px"
    >
      <FormControl maxW="250px">
        <FormLabel color="white">Usuário</FormLabel>
        <Input
          type="text"
          value={username}
          onChange={({ target }) => setUsername(target.value)}
          color="white"
        />
      </FormControl>
      <FormControl maxW="250px">
        <FormLabel color="white">Senha</FormLabel>
        <Input
          type="password"
          value={password}
          onChange={({ target }) => setPassword(target.value)}
          color="white"
        />
      </FormControl>
      <Button
        backgroundColor={getColor("TOXIC_ZOE_LAVERNE")}
        color="white"
        _hover={{ backgroundColor: getColor("TOXIC_ZOE_LAVERNE") }}
        onClick={() => {
          if (!fieldsInvalid) {
            mutation.mutate({ user: username, password });
          } else {
            toast.error("Preencha todos os campos!");
          }
        }}
        isLoading={mutation.isPending}
      >
        Acessar
      </Button>
    </Flex>
  );
}
