// https://colornames.org/

const colors = {
  TOXIC_ZOE_LAVERNE: "#0F6E51",
  DRAGO_DE_SAO_FRANCISCO: "#6B6B6C",
  CAPE: "#383A39",
  DONT_EMBARRASS_ME: "#98CA48",
  KINDA_DULLISH_BLUE: "#478BC9",
  MUSTARD_YELLOW: "#FAC52C",
};

export const getColor = (colorName) => {
  return colors[colorName] || "";
};
